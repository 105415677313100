import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-button',
  templateUrl: './index-button.component.html',
  styleUrls: ['./index-button.component.scss'],
})
export class IndexButtonComponent implements OnInit {
  @Input() text: string;
  @Input() url: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() doGo: boolean;

  private go: boolean;
  private isLink: boolean;
  constructor(private router: Router) { }

  ngOnInit() {
    this.isLink = (this.type || '') == 'link' ? true : false;
    this.go = this.doGo || false;

  }
  goTo(){
    this.router.navigateByUrl(this.url + '?v=' + Math.floor(Math.random() * 100000000000));
  }
  open(url){
    window.open(url, "_system");
  }
}
