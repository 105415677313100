export const createSchema: string = `
CREATE TABLE IF NOT EXISTS images (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  barcode VARCHAR(30)  NOT NULL,
  number_photo INTEGER NOT NULL,
  img BLOB,
  preview BLOB,
  last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);
CREATE INDEX IF NOT EXISTS images_index_name_barcode ON images (barcode);
CREATE INDEX IF NOT EXISTS images_index_number_photo ON images (number_photo);

PRAGMA user_version = 1;
`;